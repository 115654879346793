.login {
  background-color: #000;
  /* background-color: rgb(26, 32, 44); */
}
.login-container {
  justify-content: space-evenly;
}
.login-container h1 {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.4em;
}

.registration-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.registration-form > input {
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-form > input {
  margin-bottom: 10px;
}
